@import '../../../styles/variables';

.root {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.textarea {
	width: 100%;
	height: 140px;
	resize: none;
	padding: 18px 24px;
	background: $body-color;
	border: none;
	border: 1px solid;
	transition: 0.24s ease;
}

.error {
	border: 1px solid $color-error;
	background: lighten($red, 42%);

	&::placeholder {
		color: $color-error;
		// font-weight: bold;
	}

	&Value {
		color: $color-error;
	}
}

textarea {
	&::placeholder {
		font-family: $default-font;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $gray;
		user-select: none;
	}

	& {
		font-family: $default-font;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $text-color;
	}

	&:focus {
		outline: none;
		background: $gray-light;
	}

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: none;
		cursor: pointer;
		padding: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(146, 146, 146, 0.5);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(146, 146, 146, 0.9);
	}
}
