@import '../../../styles/variables';

.companies-trust {
	padding: 0 0 150px 0;

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 40px;
		align-items: center;
	}

	&__title {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			white-space: nowrap;
			background-color: #a2ea4a;
			padding: 20px 30px;
			user-select: none;
			transform: rotate(-2deg);
		}

		&::after {
			content: '';
			background-image: url('../../../static/img/svg/plus.svg');
			background-repeat: no-repeat;
			position: absolute;
			display: flex;
			width: 17px;
			height: 17px;
			left: -18%;
		}

		&::before {
			content: '';
			background-image: url('../../../static/img/svg/plus.svg');
			background-repeat: no-repeat;
			position: absolute;
			display: flex;
			width: 17px;
			height: 17px;
			right: -18%;
		}

		&-decor {
			position: absolute;
			z-index: -1;
			top: -20%;
		}
	}

	&__decor {
		z-index: -1;

		&-line1 {
			position: absolute;
			left: 0;
			transform: translate(-572px, 322px);
		}

		&-line2 {
			position: absolute;
			right: 0;
			transform: translate(446px, -758px);
		}
	}

	&__list-logos {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 24px 80px;

		a {
			transition: 0.16s ease-in;

			&:hover {
				transform: scale(1.06);
			}
		}
	}
}

// media

@media screen and (max-width: 1300px) {
	.companies-trust {
		&__decor {
			&-line1 {
				transform: translate(-630px, 400px);
			}

			&-line2 {
				transform: translate(540px, -758px);
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.companies-trust {
		&__decor {
			&-line1 {
				transform: translate(-630px, 500px);
			}

			&-line2 {
				transform: translate(600px, -758px);
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.companies-trust {
		&__decor {
			&-line1 {
				transform: translate(-630px, 540px);
			}

			&-line2 {
				transform: translate(600px, -900px);
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.companies-trust {
		padding: 0 0 100px 0;

		&__wrapper {
			gap: 32px;
		}

		&__title {
			h2 {
				font-size: 24px;
				line-height: 24px;
			}

			&-decor {
				top: -34%;
				scale: 0.8;
			}
		}

		&__list-logos {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 28px 44px;

			a {
				display: flex;
				width: 100%;
				aspect-ratio: 5/2;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		&__decor {
			&-line1 {
				display: none;
			}

			&-line2 {
				display: none;
			}
		}
	}
}

@media screen and (max-width: $mobile) {
	.companies-trust {
		padding: 36px 0 100px 0;
	}
}

@media screen and (max-width: $mobile-sm) {
	.companies-trust {
		&__list-logos {
			grid-template-columns: 1fr 1fr;
			gap: 28px 56px;
		}
	}
}
