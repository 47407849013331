@import '../../../styles/variables';

.root {
	position: relative;
	display: flex;
	padding: 200px 0 160px 0;

	h2 {
		padding-bottom: 80px;
		text-align: center;
	}
}

.container {
	position: relative;
}

.decoration {
	z-index: -1;

	.decorationImg {
		position: absolute;
		width: 1365.23px;
		right: 0px;
		top: 0px;
		transform: translate(60%, 46%);
	}

	.decorationImg2 {
		position: absolute;
		width: 846px;
		height: 953.86px;
		left: 0px;
		top: 0px;
		transform: translate(-72%, 5%) scale(-1, -1) rotate(69deg);
	}
}

.wrapper {
	display: flex;
	gap: 60px;
	justify-content: space-between;
}

.info {
	padding-top: 44px;
	display: flex;
	flex-direction: column;
	gap: 60px;

	.block {
		display: flex;
		flex-direction: column;
		gap: 24px;

		h5 {
			white-space: pre-wrap;
		}

		.item {
			display: flex;
			flex-direction: column;
			gap: 12px;

			a {
				display: flex;
				align-items: center;
				gap: 8px;
			}
		}
	}
}

.form {
	position: relative;
	max-width: 540px;
	padding: 36px;
	outline: 1px solid;
	background: $body-color;
	display: flex;
	flex-direction: column;
	gap: 30px;

	.header {
		display: flex;
		flex-direction: column;
		gap: 10px;
		text-align: center;

		.note {
			color: $color-error;
			font-weight: 500;
		}

		h5 {
			font-weight: 600;
			font-size: 24px;
			line-height: 30px;
		}
	}

	.fields {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.footer {
		text-align: center;
		color: $gray;
	}

	&::after {
		content: '';

		position: absolute;
		z-index: -1;
		top: 28px;
		left: 28px;

		width: 100%;
		height: 100%;

		background: $red;
		outline: 1px solid;
	}
}

// media

@media screen and (max-width: $desktop) {
	.wrapper {
		flex-direction: column;
		align-items: center;
	}
}

@media screen and (max-width: $tablet) {
	.root {
		display: flex;
		padding: 140px 0 140px 0;

		h2 {
			padding-bottom: 40px;
		}
	}

	.wrapper {
		gap: 44px;
	}

	.info {
		gap: 44px;
	}
}

@media screen and (max-width: 500px) {
	.form {
		padding: 24px;
		gap: 24px;
	}
}
