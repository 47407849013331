@import '../../../styles/variables';

.hero {
	padding-top: 260px;
	padding-bottom: 100px;
	position: relative;
	user-select: none;

	&__dec {
		position: absolute;
		width: 1372.73px;
		height: 1099px;
		top: 0;
		right: 0;
		transform: translate(58%, -35%);
	}

	.container {
		position: relative;
	}

	&__go {
		position: absolute;
		width: 254.25px;
		height: 252.94px;
		top: 7%;
		right: 9%;
		z-index: 2;

		&-img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-51%, -47%);

			img {
				animation: letters 25s linear infinite;
			}
		}

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		span {
			transform: translate(-50%, -50%) rotate(-7.81deg);
			font-style: normal;
			font-weight: 900;
			font-size: 54.1961px;
			line-height: 54px;
			font-family: $additional-font;
		}
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 145px;
		line-height: 145px;
		text-transform: uppercase;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			top: -21px;
			right: 112px;
			width: 44px;
			height: 44px;
			background: url(../../../static/img/hero/title-star.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		span {
			&:nth-child(1) {
				position: relative;
				z-index: 2;
				padding-right: 45px;
			}
		}

		&-span {
			color: $body-color;
			position: relative;
			z-index: 1;

			&::before {
				position: absolute;
				content: '';
				width: 116.1%;
				height: 92.3%;
				display: flex;
				top: 50%;
				left: 50%;
				transform: translate(-50.45%, -51%);
				background: $red;
				z-index: -1;
			}

			&::after {
				position: absolute;
				content: '';
				width: 455px;
				height: 416px;
				top: 0%;
				left: 50%;
				transform: translate(-51%, -29%);
				background: url(../../../static/img/hero/span-dec-min.png);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -2;
			}
		}
	}
}

.hero-top {
	margin-bottom: 49px;
	position: relative;

	&__decor {
		position: absolute;
		top: -65px;
		left: 0;
		display: flex;

		span {
			display: block;
			width: 13px;
			height: 13px;
			border: 1px solid #000000;
			border-radius: 50%;
			margin-right: 14px;
			&:nth-child(1) {
				background: black;
			}
			&:nth-child(5) {
				background: black;
			}
			&:nth-child(3) {
				margin-right: 56px;
			}
		}
	}
}

.hero-bottom {
	position: relative;

	&__txt {
		background: #a2ea4a;
		padding: 36px 58px 48px;
		position: relative;
		width: 100%;
		max-width: 523px;

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 19px;
			left: 25px;
			background: black;
			z-index: -1;
		}
	}

	&__txt-p {
		color: black;
		text-transform: uppercase;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 143.5%;
		font-family: $additional-font;
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 116px;
		height: 41px;
		position: absolute;
		bottom: 0;
		right: 0;
		outline: 1px solid black;
		transition: 0.3s;
		cursor: pointer;

		svg {
			fill: black;
			transition: 0.3s;
		}

		&:hover {
			background: black;

			svg {
				fill: #a2ea4a;
			}
		}
	}

	&__letters {
		position: absolute;
		top: 31px;
		left: 0;
		width: 100%;
		z-index: -1;

		img {
			flex-shrink: 0;
			margin-left: 97px;
		}
	}

	&__letters-mob {
		display: none;
	}
}

.js-marquee-wrapper {
	width: 100000px;
	transform: translateX(0px);
	animation: 41.572s linear 0s infinite normal none running
		marqueeAnimation-9340095;
}

// media

@media screen and (max-width: 1540px) {
	.hero {
		&__title {
			font-size: 125px;
		}
	}
}

@media screen and (max-width: 1440px) {
	.hero {
		&__title {
			font-size: 115px;
			line-height: 140%;
		}
	}
}

@media screen and (max-width: 1240px) {
	.hero {
		&__title {
			font-size: 105px;
		}
	}
}

@media screen and (max-width: $desktop) {
	.hero {
		&__title {
			font-size: 85px;
		}

		&__dec {
			right: -200px;
		}
	}

	.hero-bottom {
		z-index: 3;

		&__letters {
			top: 150px;

			img {
				width: 900px;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.hero {
		padding-top: 170px;

		&__title {
			&::before {
				top: auto;
				bottom: -1%;
				right: 50px;
			}
		}

		&__go {
			right: auto;
			left: -3%;
			top: auto;
			bottom: 12%;
			width: 134px;
			height: 134px;

			span {
				display: none;
			}

			&-img {
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}

	.hero-bottom {
		&__txt {
			padding: 40px;

			&-p {
				font-size: 16px;
				line-height: 140%;
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.hero {
		padding-bottom: 80px;

		&__title {
			font-size: 55px;
			max-width: 575px;

			span {
				&:nth-child(1) {
					padding-right: 14px;
				}
			}
		}

		&__dec {
			display: none;
		}

		&__title-span {
			&::after {
				display: none;
				z-index: 2;
			}
		}
	}

	.hero-bottom {
		&__letters {
			display: none;
		}

		&__letters-mob {
			display: block;
			position: absolute;
			bottom: -46px;
			left: 104px;
			z-index: -1;
		}
	}
}

@media screen and (max-width: $mobile) {
	.hero {
		padding-top: 123px;

		&__title {
			font-size: 40.8777px;
			line-height: 41px;
			max-width: 575px;
			padding-left: 7px;
			z-index: 4;

			span {
				&:nth-child(1) {
					padding-right: 10px;
				}
			}

			&::before {
				bottom: -76%;
				right: 40px;
				z-index: 3;
			}
		}

		&__go {
			left: -1%;
			bottom: -14%;
			width: 139px;
			height: 139px;
		}
	}

	.hero-top {
		margin-bottom: 40px;

		&__decor {
			top: -25px;
			left: 10px;

			span {
				width: 5.82px;
				height: 5.82px;
				border: 0.447368px solid #000000;
				margin-right: 6.26px;

				&:nth-child(3) {
					margin-right: 25.05px;
				}
			}
		}
	}

	.hero-bottom {
		&__txt {
			padding: 17.35px 27.2px 21.1px 27.67px;
			margin-left: 10px;
			max-width: calc(100% - 89px);

			&::before {
				top: 9px;
				left: 11px;
			}

			&-p {
				font-size: 10.3175px;
				line-height: 143.5%;
			}
		}

		&__link {
			width: 54px;
			height: 20px;

			svg {
				width: 17px;
			}
		}

		&__letters {
			top: 81px;

			img {
				width: 400px;
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.hero {
		&__title {
			font-size: 35px;
		}
	}
}
