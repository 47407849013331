@import '../../../styles/variables';

.examples {
	padding-top: 128px;
}

.examples-content {
	display: flex;

	&__item {
		width: 50%;
	}
}

.examples-info {
	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 81.931px;
		line-height: 113%;
		text-transform: uppercase;
		font-family: $additional-font;

		span {
			color: #3191d0;
		}

		&-border {
			display: block;
			width: 100%;
			max-width: 461.5px;
			height: 1px;
			background: black;
			position: relative;
			margin-top: 29px;
			margin-bottom: 55px;

			&::after {
				position: absolute;
				content: '';
				width: 17px;
				height: 17px;
				top: 50%;
				transform: translateY(-50%);
				background: url(../../../static/img/about-us/plus.svg);
				background-size: contain;
				background-position: 50%;
				background-repeat: no-repeat;
				right: -36px;
			}
		}
	}

	&__links {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 18px;

		a {
			display: block;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			text-decoration: none;
			transition: 0.16s;
			color: $text-color;
		}
	}
}

.examples-img {
	position: relative;
	height: 600px;

	&__star {
		position: absolute;
		bottom: 159px;
		right: -31px;

		img {
			animation: letters 25s linear infinite;
		}
	}

	&__camera {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-58%, -53%);
	}

	&__txt {
		position: absolute;
		right: -1px;
		top: -3px;
	}

	&__circles {
		position: absolute;
		top: 51px;
		left: 138px;
		display: flex;

		span {
			display: block;
			border: 1px solid #000000;
			border-radius: 50%;
			margin-right: 14px;
			width: 6.5px;
			height: 6.5px;
			margin-right: 7px;

			&:nth-child(1) {
				background: black;
			}

			&:nth-child(3) {
				margin-right: 28px;
			}

			&:nth-child(5) {
				background: black;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

// media

@media screen and (max-width: 1300px) {
	.examples-info {
		&__title {
			font-size: 60px;
		}
	}

	.examples-info {
		&__links {
			a {
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.examples-content {
		display: block;
	}

	.examples-info {
		width: 100%;

		&__title {
			font-size: 50px;
		}

		&__links {
			width: 71%;

			a {
				font-size: 14px;
				word-wrap: break-word;
			}
		}
	}

	.examples-img {
		margin-left: auto;
		margin-top: -150px;
		margin-bottom: -100px;

		&__camera {
			width: 550px;

			img {
				width: 100%;
			}
		}

		&__star {
			width: 175px;

			img {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.examples-info {
		&__title {
			font-size: 42px;
		}

		&__lins {
			a {
				font-size: 12px;
			}
		}
	}

	.examples-img {
		height: 300px;

		&__camera {
			width: 450px;
			top: 71%;
		}

		&__star {
			bottom: 0;
		}

		&__circles {
			top: -5px;
			left: 36px;
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.examples {
		padding-top: 50px;
	}

	.examples-img {
		margin-top: 30px;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;

		&__txt {
			right: auto;
			left: -45px;

			img {
				height: 100px;
			}
		}

		&__star {
			width: 100px;
			right: -58px;
		}
	}

	.examples-info {
		&__title {
			width: 100%;
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;
			font-size: 35px;
			&-border {
				width: 84%;
			}
		}

		&__links {
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			max-width: 450px;
		}
	}
}
