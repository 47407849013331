@import './fonts';
@import './variables';
@import './mixins';
@import './scafolding';
@import './global';

// Animation ============================================================

@keyframes letters {
	100% {
		transform: rotate(360deg);
	}
}

.link {
	transition: 0.24s ease;
	text-decoration: underline !important;

	&:hover {
		color: $red;
	}
}

// Tasks ============================================================

.tasks {
	width: 100%;
	max-width: 987px;
	margin-left: auto;
	margin-right: auto;
	background: #eae7dc;
	position: relative;

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 14px;
		line-height: 147%;
		text-transform: uppercase;
		padding: 10px;
		border: 1px solid #000000;
		font-family: $additional-font;
		text-align: center;
	}

	&__decor-goods {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-59%, -51%);
	}
}

.tasks-list {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: 50%;
		padding: 24px 80px;
		min-height: 110px;
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 147%;
		border-left: 1px solid #000000;
		border-right: 1px solid #000000;
		border-bottom: 1px solid #000000;

		&:nth-child(2n) {
			border-left: none;
		}

		&-txt {
			position: relative;

			&::before {
				position: absolute;
				content: '';
				width: 5px;
				height: 5px;
				background: $red;
				left: -23px;
				top: 10px;
			}
		}
	}
}

// media

@media screen and (max-width: 1300px) {
	.tasks {
		&::before {
			display: none;
		}

		&::after {
			display: none;
		}

		&__decor-goods {
			width: 200px;
			transform: translate(-46%, -51%);
			z-index: -1;

			img {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.tasks-list {
		&__item {
			padding: 28px 50px 28px 50px;
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.tasks {
		background: transparent;

		&__title {
			border: none;
			margin-bottom: 30px;
		}
	}

	.tasks-list {
		display: block;

		&__item {
			width: 100%;
			max-width: 100%;
			background: #eae7dc;
			margin-bottom: 20px;
			border: 1px solid black;

			&:last-child {
				margin-bottom: 0;
			}
			&:nth-child(2n) {
				border-left: 1px solid black;
			}
		}
	}
}

@media screen and (max-width: 365px) {
	.tasks__decor-goods {
		transform: translate(-60%, -51%);
	}
}
