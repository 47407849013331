@import '../../../styles/variables';

.hero-goods {
	padding-top: 175px;
	padding-bottom: 65px;
	position: relative;

	&__decor {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(54%, 10%);
		pointer-events: none;
	}

	&__decor-m4 {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(0%, 58%);
		pointer-events: none;
	}

	.breadcrumbs {
		margin-bottom: 52px;
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 72px;
		line-height: 80px;
		text-transform: uppercase;
		color: black;
		font-family: $additional-font;
		position: relative;
		margin-bottom: 176px;

		&::before {
			position: absolute;
			content: '';
			width: 490px;
			height: 329.2px;
			top: -52px;
			left: -62px;
			background: url(../../../static/img/hero-goods/dec1.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			z-index: -1;
		}

		&::after {
			position: absolute;
			content: '';
			width: 490px;
			height: 329.2px;
			top: -52px;
			right: 46px;
			background: url(../../../static/img/hero-goods/dec2.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			z-index: -1;
		}

		span {
			display: block;
		}

		&-span1 {
			margin-left: 28px;
		}

		&-span2 {
			margin-left: 366px;
		}

		&-span3 {
			color: #3191d0;
			margin-left: 27px;
		}
	}
}

// media

@media screen and (max-width: 1440px) {
	.hero-goods {
		&__title {
			font-size: 50px;

			span {
				display: inline;
			}

			&-span1 {
				margin-left: 0;
			}

			&-span2 {
				margin-left: 0;
			}

			&::before {
				left: auto;
				right: 40px;
				top: -162px;
				width: 200px;
			}

			&::after {
				display: none;
			}
		}

		&__decor-m4 {
			width: 500px;
			top: 45px;
			img {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.hero-goods {
		padding-top: 140px;

		&__title {
			font-size: 40px;
			line-height: 130%;

			span.hero-goods__title-span3 {
				display: block;
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.hero-goods {
		padding-top: 90px;
	}
}

@media screen and (max-width: $mobile-big) {
	.hero-goods {
		&__title {
			font-size: 35px;
		}

		&__decor-m4 {
			width: 326px;
			top: 147px;
			right: -63px;
		}
	}
}

@media screen and (max-width: $mobile) {
	.hero-goods {
		&__decor {
			z-index: 1;
			transform: translate(76%, 10%);
		}

		&__title {
			font-size: 26px;
			margin-bottom: 101px;
		}
	}
}

@media screen and (max-width: 365px) {
	.hero-goods {
		&__title {
			font-size: 19px;
			margin-bottom: 164px;
		}
	}
}
