.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.apple-fix {
	width: 100%;
	position: fixed;
}

.no-scroll {
	overflow: hidden;
}

.over-hidden {
	overflow: hidden;
}

.clearfix {
	&::after {
		content: '.';
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	@include heightVH(100, min); //ios
	width: 100%;
	overflow-x: hidden;
	position: relative;

	&.sticky {
		overflow: unset;
	}

	.header {
		flex: 0 0 auto;
	}

	main {
		position: relative;
		overflow: hidden;
		flex: 1 0 auto;
	}

	.footer {
		flex: 0 0 auto;
	}
}

.section-title {
	font-family: $additional-font;
	font-weight: black;
}

.sl-nav {
	user-select: none;
	z-index: 1;
	display: flex;
	align-items: center;
	pointer-events: none;
	justify-content: flex-end;

	&__btn {
		pointer-events: auto;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 42px;
		height: 42px;
		background-color: transparent;
		border: 1px solid black;
		cursor: pointer;
		fill: black;
		transition: all 0.16s ease-in;
		transition-property: fill, stroke, background-color;

		&--prev {
			margin-right: 18px;

			svg {
				transform: rotate(-180deg);
			}
		}

		svg {
			fill: #fff;
			transition: all 0.16s ease-in;
		}

		background-color: black;
		&:hover {
			svg {
				fill: black;
			}

			background-color: transparent;
		}
		&.swiper-button-disabled {
			svg {
				fill: black;
			}

			pointer-events: none;
			background-color: transparent;
		}
	}
}
