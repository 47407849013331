@import '../../../../styles/variables';

.solution-template {
	padding-top: 60px;
	padding-bottom: 160px;
	position: relative;

	.container {
		position: relative;
		z-index: 2;
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 24px;
		line-height: 147%;
		text-transform: uppercase;
		font-family: $additional-font;
		margin-bottom: 19px;
		text-align: center;
	}

	// lg-ultragear

	&__lg-ultragear {
		.solution-template-content {
			&__decor {
				&-span1 {
					position: absolute;
					left: 0px;
					top: 0px;
					transform: translate(-68%, 8%);
				}

				&-span2 {
					position: absolute;
					right: 0px;
					top: 0px;
					transform: translate(66%, -15%);
				}
			}

			&__img {
				&-span {
					position: absolute;
					right: -29px;

					&::after {
						position: absolute;
						content: '';
						background: url(../../../../static/img/cases/lg-ultragear/solution/arrows-green.svg);
						background-size: contain;
						background-repeat: no-repeat;
						width: 65.75px;
						height: 108.359px;
						bottom: -86px;
						right: 50px;
					}
				}
			}
		}
	}

	// sbermegasetup

	&__sbermegasetup {
		.solution-template-content {
			&__img {
				&-span {
					position: absolute;
					display: flex;
					top: 20px;
					right: -72px;
				}

				&-gif {
					position: absolute;
					display: flex;
					overflow: hidden;
					border-radius: 24px;
					top: 199px;
					right: -64px;

					img {
						width: 232px;
						height: 232px;
					}
				}

				&::after {
					position: absolute;
					content: '';
					background: url(../../../../static/img/cases/sbermegasetup/sbermegasetup-solution-sber.png);
					background-size: contain;
					background-repeat: no-repeat;
					width: 560px;
					height: 559px;
					bottom: -22px;
					left: -348px;
					z-index: -1;
				}
			}
		}
	}

	// valorant

	&__valorant {
		.solution-template-content {
			&__img {
				&-span {
					position: absolute;
					display: flex;
					top: 32px;
					right: -90px;
				}
			}
		}
	}

	// acer-predator

	&__acer-predator {
		.solution-template-content {
			&__img {
				&-span {
					position: absolute;
					display: flex;
					top: 25px;
					right: -71px;
				}
			}
		}
	}
}

.solution-template-content {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 987px;

	&__txt {
		width: 100%;
		max-width: 507px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 147%;
		padding-top: 55px;

		&-p {
			margin-bottom: 38px;
		}

		&-note {
			margin-bottom: 22px;

			span {
				font-weight: bold;
			}
		}

		&-title {
			font-style: normal;
			font-weight: 1000;
			font-size: 24px;
			line-height: 145%;
			display: flex;
			align-items: center;
			font-family: $additional-font;
			text-transform: uppercase;
			margin-bottom: 22px;

			span {
				width: 174px;
				height: 1px;
				background: black;
				position: relative;
				margin-left: 26px;
			}
		}

		&-stat {
			span {
				font-style: normal;
				font-weight: 1000;
				font-size: 24px;
				line-height: 147%;
				text-transform: uppercase;
				color: #ea263e;
				font-family: $additional-font;
				margin-right: 26px;
				flex-shrink: 0;
			}

			&-p {
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: 4px;
				line-height: 116%;
			}
		}
	}

	&__img {
		width: 100%;
		max-width: 404px;
		margin-right: 105px;
		position: relative;
		// display: flex;

		// img {
		// 	object-fit: contain;
		// }
	}
}

// media

@media screen and (max-width: 1800px) {
	// .solution-template {

	// }
}

@media screen and (max-width: 1550px) {
	.solution-template {
		&__lg-ultragear {
			.solution-template-content {
				&__decor {
					&-span1 {
						transform: translate(-88%, 14%);
					}

					&-span2 {
						transform: translate(85%, -15%);
					}
				}

				&__img {
					&-span {
						position: absolute;
						right: -76px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.solution-template {
		&__sbermegasetup {
			.solution-template-content {
				&__decor {
					&-span {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.solution-template {
		padding-bottom: 140px;

		&__lg-ultragear {
			.solution-template-content {
				&__decor {
					&-span1 {
						transform: translate(-86%, 9%);
					}

					&-span2 {
						transform: translate(83%, -19%);
					}
				}

				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;

						&::after {
							bottom: -11px;
							right: -127px;
						}
					}
				}
			}
		}

		&__sbermegasetup {
			.solution-template-content {
				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;
						width: 100%;

						img {
							width: 44vw;
						}
					}

					&-gif {
						border-radius: 16px;
						top: 18vw;
						right: -14vw;

						img {
							width: 24vw;
							height: 24vw;
						}
					}

					&::after {
						bottom: -16vw;
						left: -52vw;
						width: 64vw;
						height: 64vw;
						display: flex;
					}
				}
			}
		}

		&__valorant {
			.solution-template-content {
				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;
						width: 100%;
						padding-right: 2.4vw;

						img {
							width: 56vw;
						}
					}
				}
			}
		}

		&__acer-predator {
			.solution-template-content {
				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;
						width: 100%;

						img {
							width: 84vw;
						}
					}
				}
			}
		}
	}

	.solution-template-content {
		display: block;

		&__txt-title {
			span {
				width: 70px;
			}
		}

		&__txt {
			font-size: 14px;
		}

		&__txt-stat {
			span {
				font-size: 20px;
				// min-width: 180px;
			}
		}

		&__img {
			margin-left: auto;
			margin-right: auto;
			max-width: 260px;

			&::after {
				display: none;
			}
		}

		&__txt {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: $mobile) {
	.solution-template {
		&__sbermegasetup {
			.solution-template-content {
				&__img {
					&-span {
						img {
							width: 64vw;
						}
					}

					&-gif {
						top: 20vw;
						right: -14vw;

						img {
							width: 36vw;
							height: 36vw;
						}
					}

					&::after {
						bottom: -16vw;
						left: -52vw;
						width: 88vw;
						height: 88vw;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.solution-template {
		&__lg-ultragear {
			.solution-template-content {
				&__decor {
					&-span1 {
						display: none;
						transform: translate(-86%, 9%);
					}

					&-span2 {
						display: none;
						transform: translate(88%, -22%);
					}
				}

				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;

						&::after {
							bottom: -11px;
							right: -127px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: $mobile-sm) {
	.solution-template {
		&__lg-ultragear {
			.solution-template-content {
				&__img {
					&-span {
						justify-content: center;
						display: flex;
						position: relative;
						right: 0;

						img {
							width: 100%;
							width: 350px;
						}

						&::after {
							bottom: -11px;
							right: -80px;
						}
					}
				}
			}
		}

		&__valorant {
			.solution-template-content {
				&__img {
					&-span {
						padding-right: 2.4vw;

						img {
							width: 88vw;
						}
					}
				}
			}
		}

		&__sbermegasetup {
			.solution-template-content {
				&__img {
					&-span {
						img {
							width: 84vw;
						}
					}

					&-gif {
						top: 32vw;
						right: -14vw;

						img {
							width: 44vw;
							height: 44vw;
						}
					}

					&::after {
						bottom: 4vw;
						left: -52vw;
						width: 88vw;
						height: 88vw;
					}
				}
			}
		}
	}

	.solution-template-content__txt-title span {
		display: none;
	}

	.solution-template-content__txt-stat span {
		font-size: 18px;
		// min-width: 144px;
		margin-right: 10px;
	}

	.solution-template-content__txt-stat-p {
		font-size: 14px;
	}
}
