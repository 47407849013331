@import '../../styles/variables';

.root {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	transition: 0.3s;

	// -webkit-backdrop-filter: blur(24px);
	// backdrop-filter: blur(24px);

	.logo {
		margin: 0 169px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.burger {
		display: none;
	}
}

.scroll {
	background: rgba(250, 250, 250, 0.95);

	// -webkit-backdrop-filter: blur(24px);
	// backdrop-filter: blur(24px);

	.inner {
		padding: 15px 0;
	}
}

.inner {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 47px;
	padding-bottom: 47px;
	transition: 0.3s;
	transition-property: padding;
}

.navigation {
	display: flex;
	align-items: center;
	list-style: none;
	padding: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	width: 100%;
	color: $text-color;

	.navigationLink {
		transition: 0.3s;
		cursor: pointer;

		&:hover {
			color: #ea263e;
		}
	}
}

.left {
	justify-content: flex-end;

	.navigationItem {
		margin-left: 68px;

		&:first-child {
			margin-left: 0;
		}
	}
}

.right {
	.navigationItem {
		margin-right: 83px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.burger {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
	width: 22px;
	height: 12px;
	display: none;
	z-index: 5;
	cursor: pointer;
	z-index: 111;

	.burgerItem {
		display: block;
		position: absolute;
		width: 100%;
		height: 3px;
		background-color: black;
		border-radius: 10px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;
		border-radius: 4px;

		&:nth-child(2) {
			top: 0px;
		}

		&:nth-child(2) {
			top: 50%;
		}

		&:nth-child(3) {
			top: 50%;
		}

		&:nth-child(4) {
			top: 100%;
		}
	}

	&:hover {
		@media (hover: hover) {
			.burgerItem {
				background: $red;
			}
		}
	}
}

.burgerIsActive {
	.burgerItem {
		background-color: white;

		&:first-child {
			top: 50%;
			width: 0;
			left: 50%;
		}

		&:nth-child(2) {
			transform: rotate(45deg);
		}

		&:nth-child(3) {
			transform: rotate(-45deg);
			opacity: 1;
		}

		&:nth-child(4) {
			top: 50%;
			width: 0;
			left: 50%;
		}
	}
}

// media

@media screen and (max-width: $desktop) {
	.root {
		.logo {
			margin: 0 84px;
		}
	}

	.navigation {
		.left {
			.navigationItem {
				margin-left: 40px;
			}
		}

		.right {
			.navigationItem {
				margin-right: 60px;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.root {
		.logo {
			width: 89px;

			img {
				width: 100%;
			}
		}

		.burger {
			display: block;
		}
	}

	.inner {
		padding-top: 13px;
		padding-bottom: 13px;
	}

	.navigation {
		display: none;
	}

	.burger {
		display: block;
	}

	.scroll {
		.inner {
			padding-top: 13px;
			padding-bottom: 13px;
		}
	}
}
