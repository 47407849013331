@import '../../styles/variables';

.root {
	display: block;
	position: fixed;
	top: 0;
	right: -100%;
	background: $red;
	color: white;
	padding: 99px 33px;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	width: 283px;
	transition: 0.3s;

	.logo {
		position: absolute;
		top: 18px;
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 26px;
	}

	.item {
		a {
			display: flex;
			align-items: center;
		}

		img {
			margin-right: 16px;
		}
	}

	&.isActive {
		right: 0;
	}
}
