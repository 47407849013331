@import '../../../styles/variables';

.about-us {
	padding-top: 124px;
	padding-bottom: 217px;
	position: relative;

	&__decor {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-66%, -26%);
		pointer-events: none;
	}

	&__decor-2 {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(44%, -19%) rotate(34deg);
		pointer-events: none;

		img {
			max-width: 100%;
		}
	}

	&__img {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			width: 17px;
			height: 17px;
			top: 10px;
			left: 99px;
			background: url(../../../static/img/about-us/plus.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&::after {
			position: absolute;
			content: '';
			width: 17px;
			height: 17px;
			top: 10px;
			right: 99px;
			background: url(../../../static/img/about-us/plus.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&-no {
			position: absolute;
			bottom: 85px;
			left: 98px;
		}

		&-sleep {
			position: absolute;
			bottom: 95px;
			right: 102px;
		}
	}

	&__img-img {
		position: relative;
		width: 986px;
		height: 458.11px;

		img {
			position: absolute;
		}
	}

	&__img-ino {
		top: 40px;
		left: 18px;
	}

	&__img-ileep {
		top: 125px;
		left: 120px;
	}

	&__img-agency {
		top: 74px;
		right: -4px;
	}

	&__img-star1 {
		top: 91px;
		left: 462px;
	}

	&__img-star2 {
		top: 91px;
		left: 538px;
	}
}

.about-us-grid {
	display: flex;
	position: relative;
	width: 100%;
	max-width: 1211px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -2px;

	&::before {
		position: absolute;
		content: '';
		width: 7px;
		height: 100%;
		background: black;
		top: 0;
		left: 0;
	}

	&__item {
		width: 50%;
		border: 1px solid #000000;
		position: relative;
		padding: 40px 75px;
		display: flex;
		align-items: center;

		&:nth-child(1) {
			padding-left: 93px;

			.about-us-grid__item-title {
				left: 93px;
			}
		}
	}

	&__item-title {
		position: absolute;
		top: -1px;
		left: 73px;
		transform: translateY(-50%);
		background: $red;
		padding: 9px 17px;
		color: white;
		font-family: $additional-font;
		font-size: 13px;
		line-height: 137%;
		text-transform: uppercase;
		font-weight: 1000;
	}

	&__item-txt {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 137%;
	}
}

// media

@media screen and (max-width: $desktop) {
	.about-us {
		padding-bottom: 156px;

		&__decor {
			width: 600px;
			img {
				width: 100%;
			}
		}
		&__img-img {
			width: 700px;
			height: 320.6px;
			margin-left: auto;
			margin-right: auto;
		}
		&__img-ino {
			width: 196px;
			top: 28px;
			left: 12.6px;
		}
		&__img-ileep {
			width: 587px;
			top: 87.5px;
			left: 84px;
		}
		&__img-agency {
			width: 249px;
			top: 51px;
			right: -3px;
		}
		&__img-star1 {
			width: 39px;
			top: 67px;
			left: 308px;
		}
		&__img-star2 {
			width: 39px;
			top: 67px;
			left: 358px;
		}
		&__img-no {
			left: 54px;
		}
		&__img-sleep {
			bottom: 48px;
			right: 32px;
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.about-us {
		padding-top: 68px;
		&__decor {
			top: -131px;
			width: 504px;
		}
		&__decor-2 {
			top: auto;
			bottom: -310px;
			width: 600px;
			right: 31px;
		}

		&__img {
			&::before {
				left: 9px;
				width: 10px;
				height: 10px;
				top: -23px;
			}
			&::after {
				right: 9px;
				width: 10px;
				height: 10px;
				top: -23px;
			}
		}
		&__img-img {
			width: 100%;
			max-width: 600px;
			height: 147.6px;
		}
		&__img-no {
			bottom: -36px;
			left: 9px;
			width: 26px;
		}
		&__img-sleep {
			bottom: -31px;
			right: 1px;
			width: 188px;
		}
		&__img-ino {
			width: 126px;
			top: 13px;
			left: 9.6px;
		}
		&__img-ileep {
			width: 272px;
			top: 39.5px;
			left: 41px;
		}
		&__img-agency {
			width: 120px;
			top: 23px;
			right: 8px;
		}
		&__img-star1 {
			width: 19px;
			top: 29px;
			left: 153px;
		}
		&__img-star2 {
			width: 19px;
			top: 29px;
			left: 178px;
		}
	}
	.about-us-grid {
		display: block;
		margin-top: 70px;
		&__item {
			margin-bottom: 22px;
			width: calc(100% - 20px);
			margin-left: auto;
			margin-right: auto;
			padding: 24px 27px 13px;
			&:first-child {
				padding: 30px 22px 30px 41px;
				.about-us-grid__item-title {
					left: 40px;
				}
			}
		}
		&::before {
			display: none;
		}
		&__item-title {
			font-size: 10px;
			padding: 5px 11px;
			left: 28px;
		}
		&__item-txt {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 137%;
		}
	}
}
