@import '../../../styles/variables';

.results {
	padding-top: 55px;
	padding-bottom: 113px;

	&__title-wrp {
		text-align: center;
		margin-bottom: 48px;
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 24px;
		line-height: 136%;
		text-transform: uppercase;
		font-family: $additional-font;
		display: inline;
		padding: 0 52px;
		position: relative;

		&::before {
			position: absolute;
			content: '';
			width: 28px;
			height: 28px;
			top: 50%;
			transform: translateY(-50%);
			background: url(../../../static/img/awards/star.svg);
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat;
			left: 0;
		}

		&::after {
			position: absolute;
			content: '';
			width: 28px;
			height: 28px;
			top: 50%;
			transform: translateY(-50%);
			background: url(../../../static/img/awards/star.svg);
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat;
			right: 0;
		}
	}
}

.results-grid {
	display: flex;
	margin-bottom: 52px;

	&__item {
		width: 50%;
		border: 1px solid black;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 136%;
		text-align: center;
		padding: 19px 10px 10px 10px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:nth-child(2n) {
			border-left: none;
		}

		span {
			margin-left: 9px;
			margin-right: 9px;
		}

		&-number {
			font-style: 1000;
			font-weight: normal;
			font-size: 48px;
			line-height: 136%;
			color: #ea263e;
			font-family: $additional-font;
		}
	}
}

.results-logo {
	text-align: center;
}

// media

@media screen and (max-width: 1440px) {
	.results-grid {
		&__item {
			font-size: 16px;
		}

		&__item-number {
			font-size: 30px;
		}
	}
}

@media screen and (max-width: $tablet) {
	.results {
		padding-bottom: 50px;
	}

	.results-grid {
		display: block;

		&__item {
			margin-bottom: 30px;
			width: 100%;
			max-width: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			&:nth-child(2n) {
				border-left: 1px solid black;
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.results {
		&__title {
			padding: 0 42px;
			font-size: 23.0001px;
			line-height: 23px;
		}
	}
}

@media screen and (max-width: $mobile) {
	.results-grid {
		display: block;

		&__item {
			font-size: 14px;
			text-align: left;

			span {
				margin-left: 4px;
				margin-right: 4px;
			}

			&-number {
				font-size: 20px;
				flex-shrink: 0;
				margin-left: 10px;
			}
		}
	}
}

@media screen and (max-width: 374px) {
	.results__title {
		font-size: 20.0001px;
	}
}

@media screen and (max-width: 374px) {
	.results__title {
		font-size: 18px;
	}
}
