@import '../../../../styles/variables';

.root {
	padding: 0 16px;

	.wrapper {
		display: flex;
		flex-direction: column;
		border: 1px solid black;
		height: 290px;
		overflow: hidden;
		box-sizing: border-box;
		background-color: $body-color;

		.header {
			display: flex;
			border-bottom: 1px solid black;
			height: 46px;
			flex-shrink: 0;

			justify-content: center;
			align-items: center;
			padding: 0 24px;

			span {
				width: 100%;
				text-align: center;
				font-size: 14px;
				font-family: $additional-font;
				font-weight: 700;
				letter-spacing: 14px;
				text-transform: uppercase;

				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.body {
			display: flex;
			height: 100%;

			.image {
				display: flex;
				width: 42%;
				flex-shrink: 0;
				overflow: hidden;
				justify-content: center;
				align-items: center;
				border-right: 1px solid black;
				background: #f13249;

				img {
					flex-shrink: 1;
				}
			}

			.info {
				position: relative;
				padding: 36px 44px;
				display: flex;
				flex-direction: column;
				gap: 12px;
				white-space: pre-wrap;

				span {
					font-family: $additional-font;
					color: $red;
					font-size: 13px;
					line-height: 13px;
					text-transform: uppercase;
				}

				h5 {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 3;
				}

				.smLogo {
					position: absolute;
					width: 60%;
					height: 18px;
					flex-shrink: 0;
					bottom: 36px;

					img {
						// width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

// media

@media screen and (max-width: $mobile-big) {
	.root {
		padding: 0 !important;

		.wrapper {
			height: 524px !important;

			.body {
				flex-direction: column;

				.image {
					width: 100% !important;
					border-right: 0px !important;
					border-bottom: 1px solid black;
					height: 240px;
				}

				.info {
					padding: 36px;
					height: 100%;

					h5 {
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}
}
