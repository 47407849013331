@import '../../../styles/variables';

.hero-fks {
	padding-top: 174px;
	position: relative;

	.breadcrumbs {
		margin-bottom: 44px;
	}

	&__decor {
		position: absolute;
		pointer-events: none;
		right: 0;
		top: 0;
		transform: translate(63%, 15%);
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 90px;
		line-height: 90px;
		text-transform: uppercase;
		font-family: $additional-font;
		margin-bottom: 28px;
		opacity: 0;
		display: flex;
		flex-direction: column;

		span {
			color: #3191d0;
		}
	}

	&__txt {
		padding: 31px 38px;
		font-style: normal;
		font-weight: 1000;
		font-size: 14px;
		line-height: 147%;
		text-transform: uppercase;
		color: #eae7dc;
		position: relative;
		width: 100%;
		max-width: 532px;
		background: #3191d0;
		font-family: $additional-font;
		opacity: 0;
		z-index: 1;

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0px;
			left: 0px;
			background: #3191d0;
			z-index: -1;
		}

		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0px;
			left: 0px;
			background: black;
			z-index: -2;
			transition: 0.3s;
		}

		&.is-active {
			&::after {
				top: 12px;
				left: 12px;
			}
		}
	}

	.tasks {
		margin-bottom: 119px;
		z-index: 2;

		&::before {
			position: absolute;
			content: '';
			width: 22px;
			height: 31px;
			bottom: -25px;
			left: -121px;
			background: url(../../../static/img/hero-fks/arr-btn.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&::after {
			position: absolute;
			content: '';
			width: 20px;
			height: 103px;
			top: -64px;
			right: -116px;
			background: url(../../../static/img/hero-fks/fks-arr.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.tasks-list__item {
		min-height: 126px;
	}
}

.hero-fks-content {
	display: flex;
	margin-bottom: 161px;

	&__item {
		width: 50%;
	}

	&__info {
		position: relative;
		z-index: 1;
	}

	&__img {
		position: relative;

		img {
			max-width: 100%;
		}

		&-item {
			position: absolute;
			z-index: 1;
		}

		&-cup-small {
			bottom: 23%;
			left: -10%;
		}

		&-logo {
			top: 15.5%;
			left: 8%;
		}

		&-cup-big {
			top: -20%;
			right: 0%;
		}
	}

	&__img-strip {
		height: 1px;
		background: #000000;
		position: absolute;

		&--1 {
			width: 44px;
			transform: rotate(48deg);
			bottom: 23%;
			left: 27%;
		}

		&--2 {
			width: 380px;
			top: 32%;
			right: 5%;
			transform: rotate(47deg);
		}

		&--3 {
			width: 148px;
			bottom: 29%;
			right: 8%;
			transform: rotate(47deg);
		}
	}
}

// media

@media screen and (max-width: 1440px) {
	.hero-fks {
		&__title {
			font-size: 80px;
		}
	}
}

@media screen and (max-width: $desktop) {
	.hero-fks {
		padding-top: 140px;
	}

	.hero-fks-content {
		display: block;
		margin-bottom: 70px;

		&__item {
			width: 100%;
		}

		&__img {
			height: 430px;
		}

		&__img-logo {
			left: 38%;
		}

		&__img-cup-small {
			left: 8%;
		}
	}
}

@media screen and (max-width: $tablet) {
	.hero-fks {
		padding-top: 90px;

		&__decor {
			transform: translate(72%, -21%);
		}

		&__title {
			font-size: 55px;
			line-height: 140%;
		}
	}

	.hero-fks-content {
		margin-bottom: 20px;

		&__img-cup-big {
			right: 0px;
			width: 300px;
			top: 10%;
		}

		&__img-logo {
			left: 29%;
			width: 200px;
		}

		&__img-strip {
			&--1 {
				left: 4%;
			}

			&--2 {
				right: -22%;
			}

			&--3 {
				right: 0%;
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.hero-fks {
		.tasks {
			margin-bottom: 60px;
		}
	}
}

@media screen and (max-width: $mobile) {
	.hero-fks {
		.breadcrumbs {
			margin-bottom: 20px;
		}

		&__title {
			font-size: 45px;
			line-height: 130%;
		}

		&__txt {
			padding: 25px;
			font-size: 12px;
		}
	}

	.hero-fks-content {
		&__img-cup-small {
			width: 115px;
			left: 5%;
		}

		&__img-cup-big {
			width: 250px;
			top: 17%;
			right: -13%;
		}

		&__img-logo {
			left: 19%;
		}
	}
}

@media screen and (max-width: 380px) {
	.hero-fks {
		&__title {
			font-size: 37px;
		}
	}

	.hero-fks-content {
		&__img-cup-big {
			width: 215px;
		}

		&__img-logo {
			width: 170px;
		}

		&__img-strip {
			&--2 {
				width: 230px;
				top: 11%;
			}

			&--3 {
				right: -47px;
			}
		}
	}
}
