@import '../../../../styles/variables';

.hero-template {
	margin-top: 175px;
	margin-bottom: 64px;
	z-index: 1;
	user-select: none;

	// format--text-img

	&__format--text-img {
		position: relative;

		.hero-template {
			&__content {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(2, 50%);
			}

			&__img {
				display: flex;
				justify-content: flex-end;
				padding-right: 44px;

				img {
					max-width: 100%;
				}
			}

			&__info {
				padding-top: 64px;
				display: flex;
				flex-direction: column;
				gap: 24px;

				h2 {
					font-size: 44px;
					line-height: 120%;
					white-space: pre-wrap;
				}
			}

			&__note {
				display: flex;
				background-color: #a2ea4a;
				padding: 18px 32px;
				max-width: fit-content;

				h3 {
					line-height: 100%;
					font-size: 20px;
				}
			}

			&__logo-list {
				width: 100%;
				display: flex;
				gap: 24px;
				align-items: center;

				img {
					max-width: 100%;
				}
			}
		}
	}

	// lg-ultragear

	&__lg-ultragear {
		position: relative;

		.hero-template {
			&__content {
				width: 100%;
				display: flex;
				justify-content: center;
				position: relative;
			}

			&__left {
				position: relative;
				z-index: -1;
				width: 100%;
				max-width: 100%;

				img {
					position: absolute;
					top: 55px;
					right: -50px;
				}
			}

			&__middle {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;

				&-logo {
					display: none;
				}

				.monitor {
					img {
						width: 100%;
					}
				}

				h2 {
					position: absolute;
					font-size: 72px;
					line-height: 111%;
					z-index: -1;
					top: 271px;
					text-align: center;
					white-space: nowrap;
				}
			}

			&__right {
				position: relative;
				z-index: -1;
				width: 100%;
				max-width: 100%;

				img {
					position: absolute;
					top: 58px;
					left: 44px;
				}
			}
		}
	}
}

// media

@media screen and (max-width: 1469px) {
	.hero-template {
		&__format--text-img {
			.hero-template {
				&__content {
					grid-template-columns: 44% 56%;
				}

				&__img {
					padding-right: 2.2vw;
				}

				&__info {
					padding: 0;
					justify-content: center;
					gap: 1.56vw;

					h2 {
						font-size: 3.2vw;
					}
				}

				&__note {
					padding: 1.44vw 2.4vw;

					h3 {
						font-size: 1.88vw;
					}
				}

				&__logo-list {
					width: 16vw;
					gap: 1.56vw;
				}
			}
		}

		&__valorant {
			.hero-template {
				&__info {
					h2 {
						font-size: 3.4vw;
					}
				}

				&__logo-list {
					width: 20vw;
				}
			}
		}

		&__acer-predator {
			.hero-template {
				&__info {
					h2 {
						font-size: 4vw;
					}
				}

				&__logo-list {
					width: 24vw;
				}
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.hero-template {
		margin-top: 140px;

		&__lg-ultragear {
			.hero-template {
				&__left {
					img {
						top: 64px;
						right: -88px;
						width: 340px;
					}
				}

				&__middle {
					h2 {
						font-size: 64px;
					}
				}

				&__right {
					img {
						top: 72px;
						left: 24px;
						width: 220px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.hero-template {
		margin-top: 90px;

		&__lg-ultragear {
			.hero-template {
				&__left {
					img {
						top: 48px;
						right: -88px;
						width: 280px;
					}
				}

				&__middle {
					&-monitor {
						img {
							height: 280px;
						}
					}

					h2 {
						top: 212px;
						font-size: 52px;
					}
				}

				&__right {
					img {
						top: 56px;
						left: 20px;
						width: 180px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.hero-template {
		&__format--text-img {
			.hero-template {
				&__content {
					display: flex;
					flex-direction: column-reverse;
					gap: 44px;
					padding-top: 24px;
				}

				&__img {
					width: 100%;
					padding: 0;
					justify-content: center;
				}

				&__info {
					width: 100%;
					gap: 5vw;

					h2 {
						font-size: 6.2vw;
					}
				}

				&__note {
					padding: 3.9vw 5.2vw 3.4vw 5.2vw;

					h3 {
						font-size: 4vw;
					}
				}

				&__logo-list {
					width: 42vw;
					gap: 5vw;
				}
			}
		}

		&__valorant {
			.hero-template {
				&__info {
					h2 {
						font-size: 6.6vw;
					}
				}

				&__note {
					h3 {
						font-size: 4.8vw;
					}
				}

				&__logo-list {
					width: 56vw;
				}
			}
		}

		&__acer-predator {
			.hero-template {
				&__info {
					h2 {
						font-size: 7.4vw;
					}
				}

				&__note {
					h3 {
						font-size: 4.4vw;
					}
				}

				&__logo-list {
					width: 72vw;
				}
			}
		}

		&__lg-ultragear {
			.hero-template {
				&__left {
					img {
						top: 40px;
						right: -72px;
						width: 230px;
					}
				}

				&__middle {
					&-monitor {
						img {
							height: 230px;
						}
					}

					h2 {
						top: 174px;
						font-size: 44px;
					}
				}

				&__right {
					img {
						top: 50px;
						left: 18px;
						width: 140px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $mobile) {
	.hero-template {
		&__lg-ultragear {
			.hero-template {
				&__left {
					img {
						top: 220px;
						right: -244px;
						width: 300px;
					}
				}

				&__middle {
					display: flex;
					flex-direction: column;
					padding-top: 20px;
					gap: 24px;

					&-monitor {
						img {
							height: 224px;
						}
					}

					&-logo {
						display: flex;

						img {
							width: 320px;
							height: 40px;
						}
					}

					h2 {
						top: 260px;
						font-size: 32px;
					}
				}

				&__right {
					img {
						display: none;
					}
				}
			}
		}
	}
}

// @media screen and (max-width: 365px) {
// 	.hero-template {
// 		&__title {
// 			font-size: 19px;
// 			margin-bottom: 164px;
// 		}
// 	}
// }
