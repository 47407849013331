@import '../../styles/variables';

.breadcrumbs {
	padding: 20px 0;

	&__list {
		list-style: none;
		padding: 0;
		display: flex;
		gap: 24px;
	}

	&__link {
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		gap: 12px;
		cursor: pointer;
		transition: 0.16s;

		svg {
			transition: 0.16s;
		}

		&:hover {
			svg {
				transform: translateX(-4px);
			}
		}
	}
}
