@import '../../../styles/variables';

.awards {
	padding-top: 26px;
	padding-bottom: 106px;
	position: relative;
	z-index: 2;

	&__title-wrp {
		text-align: center;
		margin-bottom: 62px;
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 36px;
		line-height: 36px;
		text-transform: uppercase;
		font-family: $additional-font;
		text-align: center;
		position: relative;
		padding: 0 64px;
		display: inline;

		&::before {
			position: absolute;
			content: '';
			width: 28px;
			height: 28px;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			background: url(../../../static/img/awards/star.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&::after {
			position: absolute;
			content: '';
			width: 28px;
			height: 28px;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background: url(../../../static/img/awards/star.svg);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

.awards-header {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	font-family: $additional-font;
	text-align: center;
	letter-spacing: 1.015em;
	border: 1px solid black;

	&__txt {
		margin-left: 11px;

		span {
			color: #ed1a1a;
		}
	}
}

.awards-grid {
	display: flex;
	border: 1px solid black;
	position: relative;

	&::after {
		width: 88px;
		height: 78px;
		position: absolute;
		content: '';
		bottom: -30px;
		left: 50%;
		transform: translateX(-38%);
		background: url(../../../static/img/awards/label-min.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__item {
		border-right: 1px solid black;
		width: 25%;
		position: relative;
		padding-top: 62px;
		padding-bottom: 47px;

		&:last-child {
			border: none;
		}
	}

	&__item-number {
		position: absolute;
		top: 60px;
		left: 45px;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		line-height: 13px;
		font-family: $additional-font;
	}

	&__item-img {
		width: 179.55px;
		height: 179.55px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 36px;

		img {
			max-width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}

	&__item-txt {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		padding: 0 20px;
		white-space: pre-wrap;
	}
}

.awards-lines {
	margin-top: 87px;

	&__item {
		padding: 9px 0 25px;
		background: linear-gradient(
			90.02deg,
			#ea263e 0.89%,
			#ff4f64 30.76%,
			#ea263e 61.12%,
			#ff465d 96.46%
		);
		font-style: normal;
		font-weight: 900;
		font-size: 11px;
		line-height: 14px;
		font-family: $additional-font;
		text-align: center;
		letter-spacing: 0.515em;
		color: #eae7dc;
		transform: rotate(0.2deg);

		&:nth-child(2) {
			transform: rotate(10.5deg) translate(-45px, -35px);
			width: calc(100% + 100px);
		}
	}
}

.awards-footer {
	width: 100%;
	max-width: 332px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	margin-top: 79px;

	&::before {
		position: absolute;
		content: '';
		width: 17px;
		height: 17px;
		top: 50%;
		transform: translateY(-76%);
		left: -240px;
		background: url(../../../static/img/about-us/plus.svg);
		background-size: contain;
		background-position: 50%;
		background-repeat: no-repeat;
	}

	&::after {
		position: absolute;
		content: '';
		width: 17px;
		height: 17px;
		top: 50%;
		transform: translateY(-76%);
		right: -240px;
		background: url(../../../static/img/about-us/plus.svg);
		background-size: contain;
		background-position: 50%;
		background-repeat: no-repeat;
	}
}

.awards-slider__controlls {
	margin: 32px 15px 0 15px !important;
}

.awards-carousel__nav--prev {
	position: absolute !important;

	transition: all 0.24s ease-in !important;
	left: 16px;

	&:disabled {
		pointer-events: none;
		left: -44px;
	}
}

.awards-carousel__nav--next {
	position: absolute !important;

	transition: all 0.24s ease-in !important;
	right: 16px;

	&:disabled {
		pointer-events: none;
		right: -44px;
	}
}

.awards-sliderClass {
	user-select: none;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

// media

@media screen and (max-width: $tablet) {
	.awards {
		padding-bottom: 96px;

		&__title-wrp {
			margin-bottom: 36px;
		}
	}

	.awards-header {
		border: none;
		margin-bottom: 14px;
		font-weight: bold;
		font-size: 10px;
		line-height: 18px;
		text-align: center;
		padding: 17px;
	}

	.awards-grid {
		flex-wrap: wrap;
		border: none;
		border-top: 1px solid black;
		width: calc(100% - 20px);
		margin-left: auto;
		margin-right: auto;

		&::after {
			width: 45px;
			bottom: -43px;
			transform: translateX(-49%);
		}

		&__item {
			width: 50%;
			padding-top: 25px;
			padding-bottom: 11px;
			border-bottom: 1px solid black;
			border-left: 1px solid black;

			&:nth-child(2n) {
				border-left: none;
			}

			&:last-child {
				border-bottom: 1px solid black;
				border-right: 1px solid black;
			}
		}

		&__item-number {
			font-size: 8px;
			line-height: 8px;
			text-align: center;
			letter-spacing: 0.17em;
			top: 18px;
			left: 17px;
		}

		&__item-img {
			max-width: 53%;
			height: auto;
			margin-bottom: 14px;
		}

		&__item-txt {
			font-size: 10px;
			line-height: 12px;
			text-align: center;
		}
	}

	.awards-footer {
		&::before {
			width: 10px;
			height: 10px;
			left: 0;
			top: 159%;
			transform: none;
		}

		&::after {
			width: 10px;
			height: 10px;
			right: 0;
			top: 159%;
			transform: none;
		}
	}

	.awards-slider__controlls {
		margin: 20px 0 0 0 !important;
	}
}

@media screen and (max-width: $mobile-big) {
	.awards {
		&__title {
			font-size: 24px;
			line-height: 24px;
			padding: 0 42px;

			&::before {
				width: 19px;
				height: 19px;
			}

			&::after {
				width: 19px;
				height: 19px;
			}
		}
	}

	.awards-lines {
		margin-top: 76px;

		&__item {
			font-size: 6.34565px;
			line-height: 8px;
			padding: 6px 0 14px;

			&:nth-child(2) {
				transform: rotate(10.5deg) translate(-45px, -28px);
			}
		}
	}

	.awards-footer {
		margin-top: 31px;
		width: calc(100% - 148px);

		img {
			width: 100%;
		}
	}

	.awards-carousel__nav {
		&--prev {
			left: 0px;
		}

		&--next {
			right: 0px;
		}
	}
}
