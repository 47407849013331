@import '../../../../styles/variables';

.tasks-template {
	position: relative;
	width: 100%;
	max-width: calc(987px + 30px * 2);
	margin-left: auto;
	margin-right: auto;
	z-index: 10;
	padding-top: 64px;

	&::after {
		content: '';
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		background: url(../../../../static/img/svg/plus.svg);
		background-size: contain;
		background-repeat: no-repeat;
		display: flex;
		width: 17px;
		height: 17px;
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 14px;
		line-height: 147%;
		text-transform: uppercase;
		padding: 12px;
		border: 1px solid black;
		font-family: $additional-font;
		text-align: center;
		background: $body-color;
	}

	&-list {
		background: $body-color;
		display: flex;
		flex-wrap: wrap;

		&__item {
			width: 50%;
			padding: 24px 80px;
			min-height: 110px;
			display: flex;
			align-items: center;
			font-size: 18px;
			line-height: 147%;
			border-left: 1px solid black;
			border-right: 1px solid black;
			border-bottom: 1px solid black;

			&:nth-child(2n) {
				border-left: none;
			}

			&-odd-element {
				width: 100%;
			}

			&-txt {
				position: relative;

				&::before {
					position: absolute;
					content: '';
					width: 5px;
					height: 5px;
					background: $red;
					left: -23px;
					top: 10px;
				}
			}
		}
	}

	// lg-ultragear

	&__lg-ultragear {
		.tasks-template {
			&__decor {
				&-span1 {
					position: absolute;
					transform: translate(-122%, -2%);
					z-index: -1;
					bottom: 0;
					left: 0;
				}

				&-span2 {
					position: absolute;
					transform: translate(117%, -39%);
					z-index: -1;
					top: 0;
					right: 0;
				}
			}
		}
	}

	// sbermegasetup

	&__sbermegasetup {
		.tasks-template {
			&__decor {
				&-span {
					position: absolute;
					display: flex;
					transform: translate(-120%, 20%);
					z-index: -1;
					bottom: 0;
					left: 0;
				}

				&-span1 {
					position: absolute;
					display: flex;
					transform: translate(120%, 27%);
					z-index: -1;
					top: 0;
					right: 0;
				}
			}
		}
	}
}

// media

@media screen and (max-width: 1300px) {
	// .tasks-template {
	// 	&__sbermegasetup {
	// 		.tasks-template {
	// 			&__decor {
	// 				&-span {
	// 					transform: translate(-120%, 16%);
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

@media screen and (max-width: $tablet) {
	.tasks-template-list {
		&__item {
			padding: 28px 50px 28px 50px;
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.tasks-template {
		&__title {
			border: none;
			font-size: 24px;
			margin-bottom: 30px;
			background: transparent;
		}

		&-list {
			display: block;
			background: transparent;

			&__item {
				width: 100%;
				max-width: 100%;
				background: $body-color;
				margin-bottom: 20px;
				border: 1px solid black;

				&:last-child {
					margin-bottom: 0;
				}

				&:nth-child(2n) {
					border-left: 1px solid black;
				}
			}
		}

		&__lg-ultragear {
			.tasks-template {
				&__decor {
					&-span1 {
						transform: translate(-30%, -13%);
						top: 0;
						bottom: auto;
					}

					&-span2 {
						transform: translate(45%, 72%);
						top: auto;
						bottom: 0;
					}
				}
			}
		}
	}
}
