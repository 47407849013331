@import '../../styles/variables';

.root {
	border-top: 1px solid #000000;
	padding: 40px 0;
	margin-top: auto;

	a {
		path {
			transition: 0.3s;
		}

		&:hover {
			path {
				fill: $red;
			}
		}
	}
}

.content {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.contentRight {
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
	}

	.contentLink {
		display: flex;
	}
}

.soc {
	display: flex;
	align-items: center;
	margin-right: 54px;

	.socLink {
		margin-right: 32px;
		display: flex;

		&:last-child {
			margin-right: 0;
		}
	}
}

// media

@media screen and (max-width: $mobile-big) {
	.root {
		padding-top: 52px;
		padding-bottom: 52px;
	}

	.content {
		display: block;
		text-align: center;

		.contentRight {
			// margin-bottom: 38px;
			margin-top: 40px;
			display: block;
		}

		.contentLink {
			display: block;
		}
	}

	.soc {
		justify-content: center;
		margin: 0;
		margin-bottom: 15px;
	}

	.copy {
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 15px;
	}
}
