@import '../../../styles/variables';

.solution2 {
	padding-top: 60px;
	padding-bottom: 231px;
	position: relative;

	.container {
		position: relative;
		z-index: 2;
	}

	&__decor {
		position: absolute;
		bottom: -141px;
		right: 0;
	}

	&__game {
		position: absolute;
		bottom: 233px;
		right: 0;
	}

	&__goods {
		position: absolute;
		top: -212px;
		left: 0px;
		transform: translateX(-12%);
		@media (min-width: 2000px) {
			transform: translateX(-6%);
		}
		@media (min-width: 2300px) {
			transform: translateX(0%);
		}
	}

	&__weapon {
		position: absolute;
		z-index: 10;
		bottom: 115px;
		left: 0px;
		transform: translateX(-38%);

		@media (min-width: 2300px) {
			transform: translateX(0%);
		}
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 24px;
		line-height: 147%;
		text-transform: uppercase;
		font-family: $additional-font;
		margin-bottom: 19px;
		text-align: center;
	}
}

.solution2-content {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1039px;

	&__txt {
		width: 100%;
		max-width: 507px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 147%;
		padding-top: 55px;

		&-p {
			margin-bottom: 38px;
		}

		&-title {
			font-style: normal;
			font-weight: 1000;
			font-size: 24px;
			line-height: 145%;
			display: flex;
			align-items: center;
			font-family: $additional-font;
			text-transform: uppercase;
			margin-bottom: 22px;

			span {
				width: 174px;
				height: 1px;
				background: black;
				position: relative;
				margin-left: 26px;

				&::after {
					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					display: inline-block;
					background: url(../../../static/img/about-us/plus.svg);
					background-size: contain;
					background-position: 50%;
					background-repeat: no-repeat;
					top: 50%;
					transform: translateY(-50%);
					right: -24px;
				}
			}
		}

		&-stat {
			span {
				font-style: normal;
				font-weight: 1000;
				font-size: 24px;
				line-height: 116%;
				text-transform: uppercase;
				color: $red;
				font-family: $additional-font;
				margin-right: 26px;
				flex-shrink: 0;
			}

			&-p {
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: 4px;
			}
		}
	}

	&__img {
		width: 100%;
		max-width: 404px;
		margin-right: 105px;
		position: relative;

		&-span {
			position: absolute;
			display: flex;
			top: 0px;
			right: -97px;
		}
	}
}

// media

@media screen and (max-width: 1800px) {
	.solution2 {
		&__goods {
			width: 320px;
			top: 0;

			img {
				width: 100%;
			}
		}

		&__game {
			width: 200px;
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);

			img {
				width: 100%;
			}
		}

		&__weapon {
			transform: translateX(-55%);
		}
	}
}

@media screen and (max-width: 1550px) {
	.solution2 {
		&__goods {
			width: 220px;
		}

		&__weapon {
			bottom: -50px;
		}
	}
}

@media screen and (max-width: 1300px) {
	.solution2 {
		&__goods {
			display: none;
		}

		&__game {
			display: none;
		}
	}
}

@media screen and (max-width: $tablet) {
	.solution2 {
		padding-bottom: 306px;
	}

	.solution2-content {
		display: block;

		&__txt-title {
			span {
				width: 70px;
			}
		}

		&__txt {
			font-size: 14px;
		}

		&__txt-stat {
			span {
				font-size: 20px;
				// min-width: 180px;
			}
		}

		&__img {
			margin-left: auto;
			margin-right: auto;
			max-width: 260px;
			justify-content: center;
			display: flex;

			&-span {
				justify-content: center;
				display: flex;
				position: relative;
				right: 0;

				img {
					width: 64vw;
				}
			}

			&::after {
				display: none;
			}
		}

		&__txt {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: $mobile) {
	.solution2-content {
		&__img {
			&-span {
				img {
					width: 80vw;
				}
			}
		}
	}
}

@media screen and (max-width: $mobile-sm) {
	.solution2-content {
		&__img {
			&-span {
				img {
					width: 96vw;
				}
			}
		}
	}

	.solution2-content__txt-title span {
		display: none;
	}

	.solution2-content__txt-stat span {
		font-size: 16px;
		// min-width: 144px;
		margin-right: 10px;
	}

	.solution2-content__txt-stat-p {
		font-size: 14px;
	}
}
