@import '../../../styles/variables';

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	cursor: pointer;
	padding: 0 44px;
	transition: 0.24s ease;
	gap: 12px;
	user-select: none;

	h5 {
		white-space: nowrap;
	}

	&:active {
		transform: translateY(2px);
	}

	&:disabled {
		opacity: 0.64;
		pointer-events: none;
	}
}

.red {
	color: white;
	background: $red;

	&:hover {
		background: darken($red, 8%);
	}
}

.black {
	color: white;
	background: $text-color;

	&:hover {
		background: lighten($text-color, 8%);
	}
}

.fileUpload {
	input {
		display: none;
	}
}
