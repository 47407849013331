/* smartphones, touchscreens */
//@media (hover: none) and (pointer: coarse) {}
/* stylus-based screens */
//@media (hover: none) and (pointer: fine) {}
/* Nintendo Wii controller, Microsoft Kinect */
//@media (hover: hover) and (pointer: coarse) {}
/* mouse, touch pad */
//@media (hover: hover) and (pointer: fine) {}

[data-whatintent='touch'],
[data-whatintent='mouse'] {
	*:focus {
		outline: none;
	}
}

* {
	box-sizing: border-box;

	//outline: none;
	//	@extend %scrollbar;
}

*::before,
*::after {
	box-sizing: inherit;
}

*::selection {
	background-color: $red;
	color: $text-color;
}

html {
	// touch-action: manipulation;
	scroll-behavior: smooth;
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: $default-font;
	font-size: 16px;
	line-height: 1.5;
	color: $text-color;
	overflow-x: hidden;
	background: $body-color;
	cursor: default;

	@media (max-width: $mobile-big) {
		font-size: 14px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

ul,
ol {
	margin: 0;

	&.no-style {
		padding: 0;
		list-style: none;
	}
}

button {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
		background: darken($red, 8%);
	}
}

img {
	pointer-events: none;
	user-select: none;
	border: none;
}

a {
	text-decoration: none;
	color: inherit;

	&:focus {
		outline: none;
	}
}

[contenteditable='true']:empty:before {
	content: attr(data-placeholder);
	color: #131313;
	font-size: inherit;
	display: block; /* For Firefox */
}

select {
	appearance: none;
	&::-ms-expand {
		// для IE
		display: none;
	}
}

input[type='submit'] {
	width: auto;
}

// input[type='number'] {
// 	-moz-appearance: textfield;
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

//for safari

// input[type='search'] {
// 	-webkit-appearance: textfield;
// }

input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

blockquote {
	margin: 0;
}

h1,
h2,
h3,
h4 {
	font-weight: bold;
}

h1 {
	font-size: 42px;
	line-height: 51px;
}

h2 {
	font-style: normal;
	font-weight: 1000;
	font-size: 36px;
	line-height: 36px;
	text-transform: uppercase;
	font-family: $additional-font;
}

h3 {
	font-size: 28px;
	line-height: 34px;
	font-style: normal;
	font-weight: 1000;
	text-transform: uppercase;
	font-family: $additional-font;
}

h4 {
	font-size: 20px;
	line-height: 20px;
	font-style: normal;
	font-weight: 1000;
	text-transform: uppercase;
	font-family: $additional-font;
}

h5 {
	font-size: 18px;
	line-height: 24px;
	font-style: normal;
	font-weight: 400;
}

h6 {
	font-size: 13px;
	line-height: 18px;
	font-style: normal;
	font-weight: 400;
}

.container {
	max-width: 1469px; //1300
	width: 100%;
	margin: auto;
	padding: 0 $grid-spacing;

	@media (max-width: $mobile) {
		padding: 0 $grid-spacing-sm;
	}
}

svg {
	flex-shrink: 0;
}
