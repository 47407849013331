// widths: 420 479 575 640 767 991 1199 1400

$mobile-sm: 479px;
$mobile: 575px;
$mobile-big: 767px;
$tablet: 991px;
$desktop: 1199px;

$grid-spacing: 30px;
$grid-spacing-sm: 20px;

$base-img: '../img/';

// fonts

$default-font: 'sfui', sans-serif; // 400
$additional-font: 'drunk', sans-serif; // 400

// colors

$text-color: #000;

$color-error: #e60000;

$red: #ea263e;
$gray: #766f6e;
$gray-light: #f3f3f5;
$body-color: #eae7dc;
