@mixin fontRuble($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
		font-family: $f-name;
		font-style:  $f-style;
		font-weight: $f-weight;
		src: url('../fonts/#{$f-url}.ttf') format('truetype');
	}
}
@mixin font($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
	  font-family: $f-name;
	  font-style:  $f-style;
	  font-weight: $f-weight;
	  src: url('../fonts/#{$f-url}.ttf') format('truetype');
	}
}

@include font('sfui', normal, 400, '../static/fonts/sfui/SFUIDisplay-Regular');
@include font('drunk', normal, 700, '../static/fonts/drunk4/DrukTextWide-Bold');
@include font('drunk', normal, 900, '../static/fonts/drunk4/DrukTextWide-Heavy');
@include font('drunk', normal, 1000, '../static/fonts/drunk4/DrukTextWide-Super');
