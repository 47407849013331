@import '../../../styles/variables';

.solution {
	padding-bottom: 100px;
	position: relative;

	.container {
		position: relative;
		z-index: 1;
	}

	&__decor {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-65%, -53%);
		pointer-events: none;

		img {
			max-width: 100%;
		}
	}

	&__title {
		font-style: normal;
		font-weight: 1000;
		font-size: 24px;
		line-height: 147%;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 48px;
		font-family: $additional-font;
	}

	&__letters {
		margin-top: 92px;
	}
}

.solution-content {
	display: flex;
	margin-bottom: 20px;

	&__img {
		width: 45.068%;
		position: relative;

		img {
			position: absolute;
			top: -208px;
			right: -68px;
		}
	}

	&__txt {
		width: 100%;
		max-width: 703px;
		flex-shrink: 0;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 147%;
		position: relative;
		margin-left: 33px;

		&::before {
			position: absolute;
			content: '';
			width: 5px;
			height: 5px;
			background: #c8c4b6;
			top: 11px;
			left: -41px;
		}

		&::after {
			position: absolute;
			content: '';
			width: 1px;
			height: calc(100% - 57px);
			background: #c8c4b6;
			top: 16px;
			left: -39px;
		}

		&-p {
			margin-bottom: 24px;
		}
	}
}

// media

@media screen and (max-width: 1440px) {
	.solution-content {
		&__txt {
			max-width: 60%;
			width: 60%;
			font-size: 14px;

			&-p {
				margin-bottom: 16px;
			}
		}

		&__img {
			width: 40%;
			text-align: right;

			img {
				width: 600px;
				position: absolute;
				top: 0%;
				right: -40px;
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.solution-content {
		&__img {
			img {
				width: 550px;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.solution {
		padding-bottom: 50px;

		&__decor {
			width: 450px;
		}

		&__letters {
			img {
				width: 1000px;
			}
		}
	}

	.solution-content {
		flex-direction: column;
		padding-bottom: 100px;

		&__img {
			order: 2;
			text-align: right;
			width: 100%;

			img {
				top: 0;
				right: 0%;
				transform: translate(27%, -78%);
			}
		}

		&__txt {
			max-width: 100%;
			width: 100%;
			margin-left: 0%;

			p {
				width: calc(100% - 245px);

				&:first-child {
					width: 100%;
				}
			}

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.solution-content {
		&__img {
			img {
				width: 390px;
			}
		}

		&__txt {
			p {
				width: calc(100% - 200px);

				&:nth-child(2) {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: $mobile) {
	.solution-content {
		padding-bottom: 0;

		&__img {
			text-align: center;

			img {
				width: 290px;
				position: static;
				transform: none;
			}
		}

		&__txt {
			p {
				width: 100%;
			}
		}
	}
}
