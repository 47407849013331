@import '../../../styles/variables';

.root {
	position: relative;
	display: flex;
	padding: 0 0 144px 0;

	.header {
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.note {
			color: $color-error;
			font-weight: 500;
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 44px;
	max-width: 746px !important;

	form {
		display: flex;
		justify-content: center;
	}
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.formRow {
		display: grid;
		gap: 16px;
		grid-template-columns: 1fr 1fr;
	}

	.footer {
		display: flex;
		gap: 16px;

		.fileUpload {
			width: 100%;
			align-items: center;
			gap: 16px;
			display: flex;
		}

		.fileName {
			display: flex;
			flex-direction: column;
			width: 120px;

			span {
				h6 {
					font-weight: bold;
				}
			}

			h6 {
				flex: 1 1 auto;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				white-space: nowrap;
			}
		}
	}
}

.decoration {
	z-index: -1;

	.decorationImg {
		position: absolute;
		width: 1372.73px;
		height: 1099px;
		top: 0;
		right: 0;
		transform: translate(62%, -12%);
	}

	.decorationImg2 {
		position: absolute;
		width: 1101.68px;
		height: 882px;
		top: 0;
		left: 0;
		transform: translate(-78%, -23%) rotate(84.02deg);
	}
}

// media

@media screen and (max-width: 640px) {
	.form {
		.formRow {
			display: grid;
			grid-template-columns: 1fr;
		}

		.footer {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			button {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 460px) {
	.form {
		.fileUpload {
			flex-direction: column;

			label {
				width: 100%;
			}
		}

		.fileName {
			flex-direction: row !important;
			align-items: center;

			gap: 6px;
			width: 100% !important;
		}
	}
}
