@import '../../../styles/variables';

.cases {
	// padding-top: 117px;
	padding-bottom: 220px;

	&__title-wrp {
		position: relative;
		width: 100%;
		max-width: 729px;
		margin-left: auto;

		&::before {
			position: absolute;
			content: '';
			width: 17px;
			height: 17px;
			background: url(../../../static/img/about-us/plus.svg);
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat;
			right: 73px;
			top: 29px;
		}

		&::after {
			position: absolute;
			content: '';
			width: 17px;
			height: 17px;
			background: url(../../../static/img/about-us/plus.svg);
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat;
			bottom: -68px;
			right: 72px;
		}
	}

	&__title-work {
		position: absolute;
		top: -12px;
		left: 12px;
	}

	&__title {
		font-family: $additional-font;
		font-style: normal;
		font-weight: 1000;
		font-size: 116.889px;
		line-height: 145px;
		text-transform: uppercase;

		.elipses {
			position: relative;
			display: block;

			&::before {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				transform: translate(-55%, -46%);
				width: 759.45px;
				height: 223.42px;
				background: url(../../../static/img/cases-slider/elipses.svg);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				z-index: -1;
			}
		}
	}
}

.swiper-pagination {
	position: static;
	text-align: right;
	font-style: normal;
	font-weight: 900;
	font-size: 11px;
	line-height: 11px;
	font-family: $additional-font;
	// margin-bottom: -2px;

	span {
		position: relative;

		&::before {
			position: relative;
			content: '0';
		}
	}
}

.sl-nav {
	width: 100%;
	max-width: 100px;
	margin-left: 20px;

	&:disabled {
		opacity: 0.5;
	}
}

.cases-slider {
	position: relative;
	overflow: visible;
	margin-left: 58px;
	margin-top: -193px;
	max-width: 995px;

	&::before {
		position: absolute;
		content: '';
		width: 95%;
		height: 1px;
		top: 64.2%;
		left: 0;
		background: black;
		transform: translate(-29.7%) rotate(47deg);
	}

	&::after {
		position: absolute;
		content: '';
		height: 1px;
		width: 38%;
		top: 51.2%;
		right: 0;
		background: black;
		transform: translate(57%) rotate(47deg);
	}

	&__controlls {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-top: 14px;
	}

	&__slide {
		opacity: 0 !important;
		pointer-events: none;

		&.swiper-slide-active {
			opacity: 1 !important;
			pointer-events: all;
		}
	}

	&__decor {
		position: absolute;
		bottom: 337px;
		left: -60px;
	}

	&__decor-top {
		display: flex;
		align-items: flex-end;
	}

	&__decor-bottom {
		position: absolute;
		top: 130px;
		left: -54px;

		img {
			animation: letters 25s linear infinite;
		}

		p {
			font-style: normal;
			font-weight: 1000;
			font-size: 40.2188px;
			line-height: 40px;
			text-transform: uppercase;
			font-family: $additional-font;
			position: absolute;
			top: 40%;
			right: -74px;
			white-space: pre-wrap;
		}
	}

	&__decor-circles {
		margin-left: 23px;

		span {
			display: block;
			width: 6.5px;
			height: 6.5px;
			border: 0.5px solid #000000;
			margin-bottom: 7px;
			border-radius: 50%;

			&:nth-child(2) {
				background: #000;
			}

			&:nth-child(3) {
				margin-bottom: 28px;
			}

			&:last-child {
				margin-bottom: 0;
				background: #000;
			}
		}
	}
}

.cases-item {
	width: 479px;
	height: 219.47px;
	border: 1px solid #000000;
	position: absolute;
	transition: 1.2s;
	opacity: 0;
	transform: translateX(-20%);

	&:hover {
		.cases-item__btn {
			opacity: 1;
		}
	}

	&:nth-child(2) {
		margin: 53px auto 61px;
	}

	&:nth-child(3) {
		margin-left: auto;
	}

	&.is-active {
		position: relative;
		transform: none;
		opacity: 1;
	}

	&__number {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-17px, -17px);
		width: 59px;
		height: 59px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $red;
		overflow: visible;
		user-select: none;

		span {
			font-weight: 1000;
			font-size: 18px;
			font-family: $additional-font;
		}
	}

	&__number-decor {
		position: absolute;
		left: 100%;
		top: 0;
		display: flex;

		span {
			display: block;
			background: black;
			height: 16px;

			&:nth-child(1) {
				width: 38px;
			}

			&:nth-child(2) {
				width: 17px;
				background: #404040;
			}

			&:nth-child(3) {
				width: 8px;
				background: #7c7c7c;
			}
		}
	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	&__txt {
		position: absolute;
		z-index: 2;
		font-style: normal;
		font-weight: 1000;
		font-size: 16px;
		line-height: 137%;
		font-family: $additional-font;
		text-transform: uppercase;
	}

	&__btn {
		position: absolute;
		bottom: 0;
		right: 0;
		background: $red;
		color: white;
		padding: 15px 25px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
		font-family: $additional-font;
		opacity: 0;
		transition: 0.3s;

		&-disabled {
			opacity: 1;
			pointer-events: none;
			background: $color-error;
		}
	}

	&__award {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		width: 100px;
		height: 100px;
		top: -50px;
		right: -50px;
		transition: 0.16s ease-in;

		img {
			height: 100%;
		}

		&:hover {
			transform: scale(1.06);
		}
	}

	&--sbermegasetup {
		.cases-item__img {
			transform: translate(-58%, -53%);
		}

		.cases-item__txt {
			width: 100%;
			max-width: 205px;
			top: 112px;
			right: 38px;
			font-size: 14px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	&--xfks {
		.cases-item__img {
			transform: translate(-48%, -65%);
		}

		.cases-item__txt {
			width: 100%;
			max-width: 124px;
			top: 70px;
			right: 81px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	&--goods {
		.cases-item__img {
			transform: translate(-41%, -37%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 202px;
			top: 114px;
			left: 55px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	&--lg-ultragear {
		.cases-item__img {
			transform: translate(-38.8%, -71.95%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 202px;
			top: 114px;
			right: 46px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	&--gigabyte {
		.cases-item__img {
			transform: translate(-40%, -52%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 137%;
			width: 100%;
			max-width: 177px;
			top: 66px;
			right: 41px;
			font-weight: 1000;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			overflow: hidden;
		}
	}

	&--faceit-visa {
		.cases-item__img {
			transform: translate(-41%, -56%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 143px;
			top: 131px;
			left: 62px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	&--faceit-visa-gazprombank {
		.cases-item__img {
			transform: translate(-36%, -72%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 354px;
			top: 114px;
			left: 42px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			overflow: hidden;
		}
	}

	&--acer-predator {
		.cases-item__img {
			transform: translate(-39%, -48%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 243px;
			top: 141px;
			left: 61px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			overflow: hidden;
		}
	}

	&--valorant {
		.cases-item__img {
			transform: translate(-56%, -47%);
		}

		.cases-item__txt {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 137%;
			width: 100%;
			max-width: 188px;
			top: 120px;
			right: 44px;
			font-weight: 800;
			letter-spacing: 0.6px;
			display: -webkit-box;
			white-space: normal;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			overflow: hidden;
		}
	}
}

// media

@media screen and (max-width: 1500px) {
	.cases {
		&__title-wrp {
			max-width: 580px;
		}

		&__title {
			font-size: 80px;
			line-height: 140%;

			.elipses {
				&::before {
					width: 592.45px;
					height: 247.42px;
					transform: translate(-62%, -46%);
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.cases {
		&__title-wrp {
			max-width: 435px;
		}

		&__title {
			font-size: 55px;

			.elipses {
				&::before {
					width: 410px;
				}
			}
		}
	}

	.cases-slider {
		margin-top: -100px;

		&__decor {
			bottom: 190px;
			left: 50px;
		}
	}
}

@media screen and (max-width: $desktop) {
	.cases {
		// padding-top: 56px;
		padding-bottom: 144px;

		&__title-wrp {
			margin-left: auto;
			margin-right: auto;

			&::before {
				left: -18px;
				top: auto;
				bottom: -36px;
				width: 10px;
				height: 10px;
			}

			&::after {
				bottom: -36px;
				width: 10px;
				height: 10px;
				right: 38px;
			}
		}
	}

	.cases-slider {
		margin-top: 140px;
	}
}

@media screen and (max-width: $tablet) {
	.cases-slider {
		&::before {
			top: 109.2%;
			left: -42px;
			width: 61%;
			display: none;
		}

		&::after {
			top: 93.2%;
			right: 9px;
			display: none;
		}

		&__decor {
			position: relative;
			bottom: 0;
			left: 177px;
		}

		&__decor-top {
			margin-top: 70px;

			img {
				width: 14px;
			}
		}

		&__decor-bottom {
			top: -86px;
			left: -248px;

			img {
				width: 146px;
			}

			p {
				top: 46%;
				right: -83px;
			}
		}
	}
}

@media screen and (max-width: $mobile-big) {
	.cases {
		&__title-wrp {
			margin-left: 28px;

			&.is-active {
				.cases__title {
					opacity: 1;
				}

				.cases__title-work {
					opacity: 1;
					transform: none;
				}
			}
		}

		&__title {
			font-size: 49px;
			line-height: 124%;
			// opacity: 0;
			transition: 1s;
			transition-delay: 0.3s;

			.elipses {
				&::before {
					width: 318px;
					transform: translate(-59%, -48%);
				}
			}
		}

		&__title-work {
			top: -22px;
			left: 5px;
			width: 133px;
			// opacity: 0;
			transform: translateX(-20%);
			transition: 1s;
		}
	}

	.cases-slider {
		margin-top: 80px;
		margin-left: 20px;
		width: calc(100% - 30px);
	}

	.cases-item {
		width: 100%;

		&__number {
			width: 37.45px;
			height: 37.45px;
			top: 6px;
			left: 6px;

			span {
				padding-top: 2px;
				font-size: 12px;
				line-height: 12px;
			}
		}

		&__number-decor {
			span {
				&:nth-child(1) {
					width: 24.12px;
					height: 10.16px;
				}

				&:nth-child(2) {
					width: 10.79px;
					height: 10.16px;
				}

				&:nth-child(3) {
					width: 5.08px;
					height: 10.16px;
				}
			}
		}

		&__award {
			width: 72px;
			height: 72px;
			top: -36px;
			right: -36px;
		}

		&--sbermegasetup {
			.cases-item__txt {
				right: 64px;
			}
		}

		&--xfks {
			.cases-item__txt {
				right: 151px;
			}
		}

		&--gygabite {
			.cases-item__txt {
				right: 98px;
			}
		}
	}
}

@media screen and (max-width: $mobile) {
	.cases {
		position: relative;

		&::before {
			position: absolute;
			content: '';
			width: 159px;
			height: 280px;
			bottom: -78px;
			right: 0;
			background: url(../../../static/img/cases-slider/decor-min.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.cases-slider {
		&::before {
			display: block;
		}

		&::after {
			display: block;
		}

		&__controlls {
			margin-top: 22px;
		}

		.swiper-pagination {
			font-size: 10px;
		}

		&__decor-circles {
			margin-left: 14px;

			span {
				width: 3.9px;
				height: 3.9px;
				border: 0.3px solid #000000;
				margin-bottom: 4.2px;

				&:nth-child(3) {
					margin-bottom: 16.8px;
				}
			}
		}
	}

	.cases-item {
		height: 139.3px;

		&__award {
			width: 64px;
			height: 64px;
			top: -32px;
			right: -32px;
		}

		&__btn {
			font-size: 9px;
			line-height: 9px;
			padding: 9px 16px;
			opacity: 1;
		}

		&:nth-child(2) {
			margin: 50px auto 49px;
		}

		&--sbermegasetup {
			.cases-item__img {
				width: 320px;
				transform: translate(-58%, -53%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				max-width: 175px;
				top: 68px;
				right: 14px;
				font-size: 12px;
			}
		}

		&--xfks {
			.cases-item__img {
				width: 398px;
				transform: translate(-48%, -65%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 12px;
				line-height: 137%;
				right: 14px;
				top: 44px;
			}
		}

		&--goods {
			.cases-item__img {
				width: 310px;
				transform: translate(-41%, -40%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 10px;
				line-height: 137%;
				top: 70px;
				left: 34px;
			}
		}

		&--valorant {
			.cases-item__img {
				width: 340px;
				transform: translate(-60%, -50%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 12px;
				max-width: 160px;

				top: 72px;
				right: 32px;
			}
		}

		&--acer-predator {
			.cases-item__img {
				transform: translate(-39%, -48%);
				width: 340px;

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 12px;
				width: 100%;
				max-width: 210px;
				top: 80px;
				left: 24px;
			}
		}

		&--lg-ultragear {
			.cases-item__img {
				width: 380px;
				transform: translate(-40%, -70.7%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 10px;
				line-height: 137%;
				top: 75px;
				left: 145px;
				max-width: 132px;
			}
		}

		&--gigabyte {
			.cases-item__img {
				width: 326px;
				transform: translate(-38%, -52%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 10px;
				line-height: 137%;
				top: 40px;
				left: auto;
				right: 3px;
				max-width: 132px;
			}
		}

		&--faceit-visa {
			.cases-item__img {
				width: 279px;
				transform: translate(-38%, -58%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 10px;
				line-height: 137%;
				top: 78px;
				left: 43px;
				max-width: 132px;
			}
		}

		&--faceit-visa-gazprombank {
			.cases-item__img {
				width: 286px;
				transform: translate(-41%, -74%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				font-size: 9px;
				line-height: 137%;
				top: 72px;
				left: 36px;
				max-width: 242px;
				letter-spacing: 0.3px;
			}
		}
	}
}

@media screen and (max-width: 374px) {
	.cases-item {
		&--sbermegasetup {
			.cases-item__img {
				width: 280px;
				transform: translate(-64%, -52%);
			}

			.cases-item__txt {
				max-width: 146px;
				font-size: 10px;
			}
		}

		&--goods {
			.cases-item__img {
				width: 280px;
				transform: translate(-41%, -40%);

				img {
					width: 100%;
				}
			}

			.cases-item__txt {
				left: 9px;
			}
		}

		&--lg-ultragear {
			.cases-item__img {
				transform: translate(-40%, -67%);
				width: 343px;
			}

			.cases-item__txt {
				left: 109px;
			}
		}

		&--visa .cases-item__txt {
			left: 12px;
		}
	}
}

@media screen and (max-width: 350px) {
	.cases__title-wrp {
		margin-left: 17px;
	}
}
